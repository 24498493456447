html,
body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.body-home {
    height: 100%;
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    background: linear-gradient(to bottom, #231f20 13%, #c1352e, #d36731);
    /* For older browser support */
    background: -webkit-linear-gradient(top, #231f20 13%, #c1352e, #d36731);
    background: -moz-linear-gradient(top, #231f20 13%, #c1352e, #d36731);
    background: -o-linear-gradient(top, #231f20 13%, #c1352e, #d36731);
}

.banner-card {
    width: 100%;
    height: 500px;
}

.img-banner {
    height: 100%;
}

.img-responsive {
    width: 100%;
}

