body {
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif; */
    /* font-family: Roboto, Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: #d36731 !important;
}

.item-style-add {
    padding: 20px !important;
    min-height: 50vh;
}

.item-style-add-mobile {
    padding: 20px !important;
}

.image-responsive {
    width: 100%;
}

.center-div {
    width: 100%;
    margin: 25px auto;
    border-top: 1px solid #ccc;
    height: 0px;
}

/*======================
    404 page
=======================*/

.page_404 {
    padding: 40px 0;
    background: #fff;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #d36731;
    margin: 20px 0;
    display: inline-block;
}
.contant_box_404 {
    margin-top: -50px;
}

.sigCanvas {
    border: solid 1px;
    border-radius: 10px;
    width: 100%;
}

.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signature-container {
    position: relative;
    text-align: center;
}

.sigCanvas {
    z-index: 1; /* This ensures the canvas is above the image */
}

.signature-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* This ensures the image is above the canvas */
}
